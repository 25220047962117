.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-center.slick-current img{
  width: 150px;
}

.slick-center.slick-current {
  margin-top: -20px;
}

.navbar-light .navbar-nav .nav-link{
  color:#7B94B5 !important
}

.dropdown-menu{
  background-color: rgba(231, 241, 251, 0.5) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0,0,0,.15) !important;
  border-radius: .25rem !important;
  box-sizing: border-box !important;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.12) !important;
  backdrop-filter: blur(10px) !important;
  min-width: 12rem !important;
  padding: 0.7rem 0 !important;
  border: 1px solid rgba(231, 241, 251, 0.5) !important;
  border-radius: 20px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.journey-slider .slick-track {
  height: 490px;
  display:flex;
}
.journey-slider .slick-track:after {
  content: "";
  height: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #E2E2E2;
  display: block;
}
.journey-slider .slick-slide:nth-child(odd) {
  align-self: flex-start;

}
.journey-slider .slick-slide:nth-child(even) {
  align-self: flex-end;

}

.journey-slider .slick-slide {
  position: relative;
  background: #ECF5FC;
  display: flex !important;
  height: 205px;
  width: 205px;
  border-radius: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 8px;
  padding: 15px;
  box-shadow: -3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25);
}


.journey-slider .slick-list {
  padding-top: 40px;
}

.journey-slider .slick-slide:nth-child(odd):before {
  content: "";
  position: absolute;
  background: #ECF5FC;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  top: calc(100% + 12px);
  z-index: 99;
}


.journey-slider .slick-slide:nth-child(odd):after {
  position: absolute;
  background: #6497C7;
  width: 18px;
  height: 18px;
  content: "";
  border-radius: 100%;
  top: calc(100% + 21px);
  z-index: 99;
}


.journey-slider .slick-slide:nth-child(even):before {
  content: "";
  position: absolute;
  background: #ECF5FC;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  bottom: calc(100% + 12px);
  z-index: 99;
}

.journey-slider .slick-slide:nth-child(even):after {
  position: absolute;
  background: #6497C7;
  width: 18px;
  height: 18px;
  content: "";
  border-radius: 100%;
  bottom: calc(100% + 21px);
  z-index: 99;
}

.journey-slider .slick-slide div {
  outline: none;
}

button.navbar-toggler:focus, span.navbar-toggler-icon:focus {
  outline: none;
}

/* .bg-light{
} */
